<template>
    <fund-page
            :title="getFundDetales.name"
            :text="getFundDetales.anons"
            :strategy="getFundDetales.detail"
            :events-documents="getFundDetales.notifications"
            :logo="getFundDetales.file_anons"
            :no-ki-rules="rules"
            :file-strategy="getFundDetales.file_strategy"
            :files="getFundDetales.files"
            :cost-data="dataCoast"
            :show-payments="getFundDetales.show_interim_payments"
            :cost-data-headers="dataCoastHeaders"
            @onBuy="buyInvestmentShares"
            @download="downloadFile"
    />
</template>

<script>
    import FundPage from "../../../components/pages/cabinet/Funds/FundPage";
    import {mapActions, mapGetters} from "vuex";
    import RWMBuyPushModalMixin from "../../../components/mixins/RWMBuyPushModalMixin";

    export default {
        name: "Fund",
        components: {FundPage},
        mixins: [RWMBuyPushModalMixin],
        watch: {
          fondChanged() {
              if (this.$route.params.fundId == this.changedFondId)
                  this.GET_FUND_DETALES(this.$route.params.fundId);
          },
          userChanged() {
              this.GET_FUND_DETALES(this.$route.params.fundId);
          }
        },
        computed: {
            ...mapGetters("FundsModule", ["getFundDetales"]),
            ...mapGetters("AuthModule", ["getUSER"]),
            ...mapGetters("AutoUpdateModule", ["fondChanged", "changedFondId", "userChanged"]),
            rules() {
                if (this.getFundDetales.file_rules_ki) {
                    return {
                        id: this.getFundDetales.file_rules_ki?.id,
                        name: this.getFundDetales.file_rules_ki?.origin_name,
                    };
                } else if (this.getFundDetales.file_rules_not_ki) {
                    return {
                        id: this.getFundDetales.file_rules_not_ki?.id,
                        name: this.getFundDetales.file_rules_not_ki?.origin_name,
                    };
                } else return null;
            },
            // 28092022 MI...
            dataCoastHeaders() {
                return this.getFundDetales.status_id === 6 && this.getFundDetales.shares.length === 1 ?
                    null:
                    {
                        date: "Дата",
                        price: "Цена пая, руб.",
                        net_asset_value: "Стоимость чистых активов, руб.",
                        interim_payments: "Промежуточные выплаты, руб",

                    }
            },
            dataCoast() {
                if (this.getFundDetales.status_id === 6 && this.getFundDetales.shares.length > 1) {
                    return this.getFundDetales.shares.slice(0,-1);
                }
                return this.getFundDetales.shares;
            }
            // ...28092022 MI
        },
        methods: {
            ...mapActions("FundsModule", ["GET_FUND_DETALES"]),
            ...mapActions("regModule", ["DOWNLOAD_FILE"]),
            downloadFile(file) {
                this.DOWNLOAD_FILE(file);
            },
            buyInvestmentShares(id) {
                this.buy({
                    toBuy: id,
                    fundStatus: this.getFundDetales.status_id,
                    fundAnkets: this.getFundDetales.ankets,
                    title: this.getFundDetales.name,
                    fundKI: this.getFundDetales.file_rules_ki != null,
                    hasNecessaryDocs: this.getFundDetales.fatca_status === 3 && this.getFundDetales.pod_ft_status === 3,
                    fatca_status: this.getFundDetales.fatca_status,
                    podft_status: this.getFundDetales.pod_ft_status,
                    kiStatus: this.getFundDetales.appl_ci_status,
                    isQualifiedInvestor: this.getUSER.qualifiedInvestor
                });
            },
        },
        mounted() {
            this.GET_FUND_DETALES(this.$route.params.fundId);
        },
    };
</script>

<style scoped lang="scss"></style>
