<template>
    <div @click="$emit('download', {id: id, name: filename})" class="document-link">
        <v-icon :hovering="false">
            {{/*filename.split(".")[1].match("pdf") ? "note-blank" : */"note"}}
        </v-icon>
        <span class="document-link-font" v-html="filename" />
    </div>
</template>

<script>
    import VIcon from "../icons/VIcon";
    export default {
        name: "RwmPdfLink",
        components: {VIcon},
        props: {
            filename: String,
            id: [String, Number]
        }
    }
</script>

<style scoped lang="scss">
    .document-link {
        display: flex;
        max-width: 100%;
        width: max-content;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        &-font {
            color: $RWM-grey;
            transition: all 300ms;
            position: relative;
            &:hover {
                color: $RWM-red;
                &:after {
                    border-color: $RWM-red;
                }
            }
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 3px;
                border-bottom: 1px solid $RWM-grey;
                transition: all 300ms;
            }
        }
        & .v-icon {
            color: $RWM-grey;
            margin-right: 44px;
        }
        &:hover .v-icon {
            color: $RWM-red
        }
    }
</style>
