<template>
    <div class="fund">
        <div class="fund_header">
            <div class="fund_header_img">
                <img :src="logo" />
            </div>
            <div class="fund_header_description">
                <div class="fund_header_description_body">
                    <div class="fund_header_description_body_img">
                        <img :src="logo" />
                    </div>
                    <h1 v-html="title" />
                </div>
                <div class="anketa" v-html="text" />
                <div class="strategy" v-html="strategy" />
                <div class="fund_header_files" v-if="fileStrategy">
                  <rwm-pdf-link
                      :filename="fileStrategy.text_name
                        ? fileStrategy.text_name+fileStrategy.origin_name.split('.')[1]
                        : fileStrategy.origin_name"
                      :id="fileStrategy.id" @download="$emit('download', $event)"
                  />
                </div>
                <div class="fund_header_files" v-if="noKiRules && JSON.stringify(noKiRules) !== '{}'">
                    <rwm-pdf-link :filename="noKiRules.name" :id="noKiRules.id" @download="$emit('download', $event)" />
                </div>
                <div class="fund_header_files" v-if="files && files.length > 0">
                  <template v-for="file in files">
                    <rwm-pdf-link
                        :key="`file-${file.id}`"
                        :filename="file.text_name ? `${file.text_name}.${file.origin_name.split('.')[1]}` : file.origin_name"
                        :id="file.id"
                        @download="$emit('download', $event)"
                    />
                  </template>
                </div>
            </div>
        </div>
        <div class="fund_action">
            <rwm-button width="100%" @click="$emit('onBuy', $route.params.fundId)">Купить</rwm-button>
        </div>
        <div class="fund_body">
            <div class="fund_body_table">
                <h2 v-if="costDataHeaders !== null">Данные о стоимости пая и СЧА</h2>
                <h2 v-else>Сумма денежных средств (стоимость имуществ),<br>на которую выдается инвестиционный пай при формировании Фонда</h2>
                <scha-table v-if="costDataHeaders !== null && costData && costData.length > 0" :headers="costDataHeaders" :data="costData" :show-payments="showPayments" />
                <div v-else>
                    <p v-if="costData && costData.length > 0" class="anketa"> <b>Цена пая, руб.:</b> {{(+costData[0].price).toLocaleString()}} </p>
                    <p v-else class="anketa">Нет данных</p>
                </div>
            </div>
            <div class="fund_body_events">
                <h2 style="padding-bottom: 8px">Сообщения о событиях фонда</h2>
                <template v-if="eventsDocuments && eventsDocuments.length > 0">
                    <rwm-agreement-document
                            class="fund_body_events_row"
                            v-for="doc in eventsDocuments"
                            :key="doc.id"
                            :date="doc.created_at"
                            :title="doc.title"
                            :file="doc.files && doc.files.length > 0 ? doc.files : null"
                            :show-done="false"
                            show-fulltime
                            @download="$emit('download', $event)"
                    />
                </template>
                <div v-else>
                    <p class="anketa">Нет данных</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmButton from "../../../RWMButton/RwmButton";
    import RwmPdfLink from "../../../RWMPdfLink/RwmPdfLink";
    import RwmAgreementDocument from "../../../RWMAgreementDocument/RwmAgreementDocument";
    import SchaTable from "../documents/forms/blocks/SchaTable";
    export default {
        name: "FundPage",
        components: {SchaTable, RwmAgreementDocument, RwmPdfLink, RwmButton},
        props: {
            title: {
                type: String,
                default: "Закрытый паевой инвестиционный фонд недвижимости «РВМ Столичная недвижимость №1»",
            },
            text: {
                type: String,
                default: "ЗПИФ недвижимости «РВМ Столичная недвижимость №1» реализует стратегию sale and lease back c крупнейшей фитнес-группой в России World Class. Фонд владеет в собственности помещениями 3-х фитнес клубов, которые сданы в долгосрочную аренду группе World Class, с правом последующего выкупа. Инвестирование в ЗПИФ недвижимости «РВМ Столичная недвижимость №1» формирует рентную доходность пайщику."
            },
            strategy: {
                type: String,
                default: "За 2020 год доходность на 1 пай составила __% годовых."
            },
            noKiRules: Object,
            eventsDocuments: Array,
            logo: String,
            costData: Array,
            costDataHeaders: Object,
            showPayments: [Boolean, null],
            fileStrategy: Object,
            files: Array
        },
        data() {
            return {
                formulaDescription: "<div style=\"display: flex; flex-direction: column; row-gap: 14px\">" +
                    "                <div style=\"display: flex; column-gap: 20px;\">" +
                    "                    <div style=\"max-width: 30px; max-height: 30px; overflow: hidden; object-fit: contain\">" +
                    "                        <svg width=\"56\" height=\"56\" viewBox=\"0 0 56 56\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" style=\"max-width: 100%; max-height: 30px;\">" +
                    "                            <path d=\"M24.9696 8.74666L5.72475 41.995C5.41681 42.527 5.25442 43.1307 5.25391 43.7454C5.2534 44.3601 5.41479 44.9641 5.72185 45.4967C6.02891 46.0292 6.47081 46.4714 7.00308 46.7789C7.53534 47.0864 8.13921 47.2483 8.75392 47.2483H47.2435C47.8582 47.2483 48.4621 47.0864 48.9944 46.7789C49.5266 46.4714 49.9685 46.0292 50.2756 45.4967C50.5827 44.9641 50.744 44.3601 50.7435 43.7454C50.743 43.1307 50.5806 42.527 50.2727 41.995L31.0279 8.74666C30.7204 8.2155 30.2787 7.77451 29.747 7.46795C29.2154 7.16138 28.6124 7 27.9987 7C27.385 7 26.7821 7.16138 26.2504 7.46795C25.7187 7.77451 25.277 8.2155 24.9696 8.74666V8.74666Z\" stroke=\"#333F4F\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>" +
                    "                        </svg>" +
                    "                    </div>" +
                    "                    <p class=\"rwm-text-fields\" style=\"max-width: 95%\">Прирост</p>" +
                    "                </div>" +
                    "                <div style=\"display: flex; column-gap: 20px;\">" +
                    "                    <div style=\"max-width: 30px; min-width: 30px; max-height: 30px; overflow: hidden; object-fit: contain\">" +
                    "                        <svg width=\"51\" height=\"36\" viewBox=\"0 0 51 36\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" style=\"max-width: 100%; max-height: 30px;\">" +
                    "                            <path d=\"M17.392 2.744C14.576 2.744 12.16 3.384 10.144 4.664C8.128 5.912 6.576 7.672 5.488 9.944C4.432 12.184 3.904 14.792 3.904 17.768C3.904 20.84 4.384 23.528 5.344 25.832C6.304 28.104 7.744 29.864 9.664 31.112C11.616 32.36 14.032 32.984 16.912 32.984C18.576 32.984 20.096 32.856 21.472 32.6C22.88 32.344 24.208 32.008 25.456 31.592V34.04C24.272 34.488 22.976 34.84 21.568 35.096C20.16 35.352 18.512 35.48 16.624 35.48C13.168 35.48 10.272 34.744 7.936 33.272C5.632 31.8 3.888 29.736 2.704 27.08C1.552 24.424 0.976 21.32 0.976 17.768C0.976 14.376 1.616 11.368 2.896 8.744C4.208 6.088 6.08 4.008 8.512 2.504C10.976 0.999998 13.952 0.247998 17.44 0.247998C20.768 0.247998 23.76 0.871998 26.416 2.12L25.312 4.568C22.848 3.352 20.208 2.744 17.392 2.744ZM46.2903 9.464H49.4103L37.9863 21.848L50.1303 35H46.8183L34.8663 22.04V35H32.1303V9.464H34.8663V21.848L46.2903 9.464Z\" fill=\"#333F4F\"/>" +
                    "                        </svg>" +
                    "                    </div>" +
                    "                    <p class=\"rwm-text-fields\" style=\"max-width: 95%\">Расчетная стоимость инвестиционного пая паевого инвестиционного фонда на конец периода, за который определяется прирост</p>" +
                    "                </div>" +
                    "                <div style=\"display: flex; column-gap: 20px;\">" +
                    "                    <div style=\"max-width: 30px; min-width: 30px; max-height: 30px; overflow: hidden; object-fit: contain\">" +
                    "                        <svg width=\"54\" height=\"36\" viewBox=\"0 0 54 36\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" style=\"max-width: 100%; max-height: 30px;\">" +
                    "                            <path d=\"M17.392 2.744C14.576 2.744 12.16 3.384 10.144 4.664C8.128 5.912 6.576 7.672 5.488 9.944C4.432 12.184 3.904 14.792 3.904 17.768C3.904 20.84 4.384 23.528 5.344 25.832C6.304 28.104 7.744 29.864 9.664 31.112C11.616 32.36 14.032 32.984 16.912 32.984C18.576 32.984 20.096 32.856 21.472 32.6C22.88 32.344 24.208 32.008 25.456 31.592V34.04C24.272 34.488 22.976 34.84 21.568 35.096C20.16 35.352 18.512 35.48 16.624 35.48C13.168 35.48 10.272 34.744 7.936 33.272C5.632 31.8 3.888 29.736 2.704 27.08C1.552 24.424 0.976 21.32 0.976 17.768C0.976 14.376 1.616 11.368 2.896 8.744C4.208 6.088 6.08 4.008 8.512 2.504C10.976 0.999998 13.952 0.247998 17.44 0.247998C20.768 0.247998 23.76 0.871998 26.416 2.12L25.312 4.568C22.848 3.352 20.208 2.744 17.392 2.744ZM34.8663 9.464V20.552H50.5143V9.464H53.2502V35H50.5143V22.904H34.8663V35H32.1303V9.464H34.8663Z\" fill=\"#333F4F\"/>" +
                    "                        </svg>" +
                    "                    </div>" +
                    "                    <p class=\"rwm-text-fields\" style=\"max-width: 95%\">Расчетная стоимость инвестиционного пая паевого инвестиционного фонда на начало периода, за который определяется прирост;</p>" +
                    "                </div>" +
                    "                <div style=\"display: flex; column-gap: 20px;\">" +
                    "                    <div style=\"max-width: 30px; min-width: 30px; max-height: 30px; overflow: hidden; object-fit: contain\">" +
                    "                        <svg width=\"31\" height=\"44\" viewBox=\"0 0 31 44\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" style=\"max-width: 100%; max-height: 30px;\">" +
                    "                            <path d=\"M26.688 0.727998V32.456H30.384V43.688H27.696V35H3.024V43.688H0.336V32.456H2.832C4.016 30.408 5.12 28.104 6.144 25.544C7.2 22.952 8.128 20.232 8.928 17.384C9.728 14.536 10.368 11.688 10.848 8.84C11.328 5.992 11.632 3.288 11.76 0.727998H26.688ZM14.256 3.272C14.128 5.48 13.808 7.88 13.296 10.472C12.816 13.064 12.192 15.688 11.424 18.344C10.688 21 9.84 23.544 8.88 25.976C7.92 28.408 6.912 30.568 5.856 32.456H23.952V3.272H14.256Z\" fill=\"#333F4F\"/>" +
                    "                        </svg>" +
                    "                    </div>" +
                    "                    <p class=\"rwm-text-fields\" style=\"max-width: 95%\">Сумма доходов от доверительного управления имуществом, составляющим паевой инвестиционный фонд, в расчете на один инвестиционный пай паевого инвестиционного фонда, начисленных за период, за который определяется прирост, в случае если выплата указанного дохода предусмотрена правилами доверительного управления паевого инвестиционного фонда.</p>" +
                    "                </div>" +
                    "            </div>"
            }
        },
    }
</script>

<style scoped lang="scss">
    .fund {
        display: flex;
        flex-direction: column;
        row-gap: 44px;
        &_header {
            display: flex;
            column-gap: 32px;
            &_img {
                display: none;
                @media screen and (min-width: 1280px) {
                    display: flex;
                    flex: 0 0 33%;
                }
                & img {
                    @media screen and (min-width: 1280px) {
                        max-width: 100%;
                        object-fit: contain;
                        object-position: top;
                    }
                }
            }
            &_description {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                row-gap: 32px;
                &_body {
                    display: flex;
                    column-gap: 32px;
                    &_img {
                        display: none;
                        @media screen and (min-width: 601px) {
                            display: flex;
                            flex: 0 0 33%;
                        }
                        @media screen and (min-width: 1280px) {
                            display: none;
                        }
                        & img {
                            max-width: 100%;
                            object-fit: contain;
                            object-position: top;
                        }
                    }
                    & h1 {
                        text-align: left;
                    }
                }
                & h3 {
                    text-align: left;
                }
            }
            &_files {
                display: flex;
                flex-direction: column;
                row-gap: 22px;
            }
        }
        &_formula {
            text-align: left;
            &_body {
                width: 100%;
                overflow: hidden;
                @media screen and (min-width: 600px) {
                    width: 350px;
                }
                & img {
                    object-fit: contain;
                    max-width: 100%;
                }
            }
        }
        &_action {
            @media screen and (min-width: 961px) {
                max-width: 33.333%;
            }
        }
        &_body {
            text-align: left;
            display: flex;
            flex-direction: column;
            row-gap: 44px;
            &_events, &_table {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                &_row {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        opacity: 0.5;
                        background-color: $RWM-grey-light;
                        top: 0;
                        @media screen and (min-width: 1280px) {
                            top: 100%;
                        }
                    }
                    &:last-child:after {
                        content: none;
                    }
                }
            }
        }
    }
    .strategy {
        text-align: left;
    }
</style>
